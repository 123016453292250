import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
import Vue from 'vue';
import VueTelInput from "vue-tel-input";
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-tel-input/dist/css/sprite.css'; // Flags styles
import 'vue-tel-input/dist/css/component.css'; // Component styles
Vue.use(VueTelInput);
import {dynamicHead} from '@/mixins/dynamic-head.js'
import contactItem from "@/modules/feedback/components/contact-item/index.vue";

export default {
  name: "feedback",
  mixins: [dynamicHead, validationMixin],
  components:{
    contactItem
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
        {name: 'contacts', title: this.$t('contacts'), slug: ''}
      ],
      payload: {
        last_name: '',
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      validationErrors: {},
      vueTel: {
        phone: '',
        props: {
          maxLen: 11,
          validCharactersOnly: true,
          mode: "international",
          dynamicPlaceholder: 'true',
          autoDefaultCountry: false,
          disabled: false,
          required: true,
          enabledCountryCode: false,
          enabledFlags: true,
          onlyCountries: [],
          ignoredCountries: [],
          autocomplete: "off",
          name: "telephone",
          inputClasses: "",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: true,
          wrapperClasses: "label"
        }
      },
    }
  },
  watch: {
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        this.crumbs[1].title = newVal.contacts
      }
    },
  },
  validations: {
    payload: {
      last_name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2)
      },
      name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2),
      },
      phone: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(7),
      },
      email: {
        email,
        maxLength: maxLength(30),
        required,
      },
      message: {
        required,
        maxLength: maxLength(250),
        minLength: minLength(10),
      },
    },
  },
  created() {
    this.fetchMeta(this.$route.name).then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
    this.fetchContacts()
  },
  computed:{
    ...mapGetters({
      meta: 'meta/meta',
      contacts: 'meta/feedback',
      feedbackLoading: 'meta/feedbackLoading',
    }),
    nameError() {
      const error = []
      if (!this.$v.payload.name.$dirty) {
        return error
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 16))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 7))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 30))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    messageError() {
      const error = []
      if (!this.$v.payload.message.$dirty) {
        return error
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.message.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 250))
      }
      if (!this.$v.payload.message.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 10))
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
  },

  methods:{
    ...mapActions({
      fetchMeta: 'meta/GET_META',
      fetchContacts: 'meta/GET_FEEDBACK',
      sendFeedback: 'meta/SEND_FEED'
    }),
    ...mapMutations({
    }),
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    },
    resetForm(){
      this.payload.name = ''
      this.payload.last_name = ''
      this.payload.email = ''
      this.payload.phone = ''
      this.payload.message = ''
      this.$v.$reset()
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.sendFeedback(this.payload).then(() => {
          this.$toasted.success(this.$t('successMessage'))
          this.resetForm()
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}